





















































































































import { Component, Vue } from "vue-property-decorator";
import {
  appointmentList,
  doctortflagList,
  doctortflagadd,
  doctortflagedit,
  doctortflagdelete,
} from "@/apis";
import { parseTime } from "@/utils";
//import Edit from "@/views/ActionCenter/ActivityManagement/module/Edit.vue";
@Component({
  components: {},
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class AppointmentList extends Vue {
  tableData: Array<any> = [];
  dialogVisible = false;
  addNew = false;
  formData = {
    start_createdate: "",
    end_createdate: "",
    patient_name: "",
    appointment_state: "",
    status: "",
  };
  formAdd = {
    name: "",
    description: "",
  };
  rules = {
    name: [{ required: true, message: "请填写标签名称", trigger: "blur" }],
    description: [
      { required: true, message: "请填写标签描述", trigger: "blur" },
    ],
  };
  formList = {
    patient_name: "",
    creator: "",
  };
  preDate = [];
  orderState = {
    "101": "待审核",
    "102": "待程控",
    "108": "程控当天",
    "104": "已完成",
    "109": "已完成",
    103: "已退款",
    105: "已退款",
    106: "已退款",
    107: "已退款",
    110: "已退款",
  };
  // 分页
  form = {
    cur_page: 1,
    page_size: 10,
  };
  totalSize = 0;
  activityState = ["筹备中", "进行中", "已完成"];
  created() {
    this.requestList();
  }
  //删除某个登陆者创建的标签
  async deleteButton(v: any) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        const result = await doctortflagdelete({ id: v.id });
        if (result.success) {
          this.requestList();
          console.log(result);

          this.$message({
            type: "success",
            message: "删除成功",
          });
        }
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }
  //点击页面上的添加标签按钮:
  addLabel() {
    this.dialogVisible = true;
    this.addNew = true;
  }
  //添加完成--点击保存按钮:
  addComplitBtn() {
    const formRef = this.$refs.formData as any;
    formRef.validate((valid: boolean) => {
      if (valid) {
        this.doctortAddflag();
      } else {
        console.log("请填充完整信息");
      }
    });
  }

  //新增标签数据提交到后台:
  async doctortAddflag() {
    const result = await doctortflagadd(this.formAdd);
    if (result.success) {
      this.requestList();
      console.log(result);
      this.cancelButton();
    }
  }

  //继续新加:
  continueAdd() {
    this.formAdd = {
      name: "",
      description: "",
    };
  }
  // 点开列表某条目的编辑按钮
  // 点开列表某条目的编辑按钮
  editButton(val: any) {
    this.dialogVisible = true;
    this.addNew = false;
    this.formAdd = {
      name: val.name,
      description: val.description,
    };
    const temp = { ...this.formAdd, id: val.id };
    this.formAdd = temp;
  }
  //编辑完成后点击完成按钮
  editComplitBtn() {
    const formRef = this.$refs.formData as any;
    formRef.validate((valid: boolean) => {
      if (valid) {
        this.labelEdit();
      } else {
        console.log("请填充完整信息");
      }
    });
  }
  //编辑完后,数据提交到后台:
  async labelEdit() {
    const result = await doctortflagedit(this.formAdd);
    if (result.success) {
      this.$message.success("删除成功");
      this.requestList();
      this.cancelButton();
    }
  }

  //取消添加:
  cancelButton() {
    (this.$refs["formData"] as any).clearValidate(["name", "description"]);
    this.dialogVisible = false;
    this.formAdd = {
      name: "",
      description: "",
    };
  }
  closeDialog() {
    (this.$refs["formData"] as any).clearValidate(["name", "modescriptionney"]);
  }
  handleSizeChange(val: number) {
    console.log(`每页 ${val} 条`);
    this.form.cur_page = 1;
    this.form.page_size = val;
    this.requestList();
  }

  handleCurrentChange(val: number) {
    console.log(`当前页: ${val}`);
    this.form.cur_page = val;
    this.requestList();
  }
  // 活动查询
  async requestList() {
    const result = await doctortflagList(this.form);
    if (result.success) {
      this.tableData = result.data;
      this.totalSize = result.page.total_size;
      console.log(result);
    }
  }

  //重置
  searchReset() {
    this.formList = {
      patient_name: "",
      creator: "",
    };
  }
  //EleMediRecord
  async searchButton() {
    const temp = {} as any;
    const Api: any = this.formList; //重新定义了下循环的对象
    for (const key in Api) {
      if (Api[key]) {
        temp[key] = Api[key];
      }
    }
    const result = await appointmentList({ ...temp, ...this.form });
    if (result.success) {
      this.tableData = result.data;
      this.form.cur_page = 1;
      this.totalSize = result.page.total_size;
    } else {
      this.$message.success("搜索失败");
    }
  }
}
